import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { useParams } from 'react-router-dom';
import Login from './login/LoginPage.js';

import ProductionIndex from "./workspace/pages/ProductionIndex.js";
import ProposalList from "./workspace/pages/lists/ProposalsList.js";
import BillsList from "./workspace/pages/lists/BillsList.js";
import ReceiptsList from "./workspace/pages/lists/ReceiptsList";
import ProgressList from "./workspace/pages/lists/ProgressList";
import ProductionView from "./workspace/pages/ProductionView.js";
import HistoryPage from './workspace/pages/lists/HistoryList.js';
import RetainerList from './workspace/pages/lists/RetainersList.js';
import ArchivedList from './workspace/pages/lists/ArchivedList.js';
import CreateProduction from './workspace/pages/CreateProduction.js';
import ClientsList from './workspace/pages/clients/ClientList.js';
import WorkspaceMenu from './menus/Menu.js';
import ProductionListIndex from './workspace/pages/ProductionList.js';
import DashboardIndex from './workspace/pages/Dashboard.js';
import ReceiptsListIndex from './workspace/pages/ReceiptsList.js';
import NotFound from './workspace/NotFound.js';


function App() {

  // const { location } = useParams(); 
  // const isViewPage = location === '/workspace';


  return (
    <div className="dark-background h-100">

      <BrowserRouter>
          <Routes>
              <Route path="/" element={<DashboardIndex />}/>
              <Route path="/new" element={<CreateProduction />} />
              <Route path="/done" element={<ReceiptsListIndex />} />
              <Route path="/productions" element={<ProductionListIndex />} />
              <Route path="/production/:key" element={<ProductionIndex />} />
              <Route path="/workspace/proposals" element={<ProposalList />} />
              <Route path="/workspace/bills" element={<BillsList />} />
              <Route path="/workspace/retainers" element={<RetainerList />} />
              <Route path="/workspace/progress" element={<ProgressList />} />
              <Route path="/workspace/receipts" element={<ReceiptsList />} />
              <Route path="/workspace/history" element={<HistoryPage />} />
              <Route path="/workspace/archived" element={<ArchivedList />} />
              <Route path="/workspace/clients" element={<ClientsList />} />


              <Route path="/:key" element={<ProductionView />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/404" element={<NotFound />} />


          </Routes>
      </BrowserRouter>

  </div>
  );
}

export default App;