import React, { useRef, useState } from 'react';
import './Windows.css';
import SignatureCanvas from 'react-signature-canvas';


const ApprovalWindow = ({ isOpen, onClose, onApprove, onApproveProposal, updateStatus, status }) => {


    const [statusUpdate, setStatusUpdate] = useState('');
    const [approvalData, setApprovalData] = useState(null); 
    const [tipRate, setTipRate] = useState(null); 
    const [windowStep, setWindowStep] = useState(0); 



    const signatureRef = useRef();

    const handle_approver_name_change = (event) => {
        const value = event.target.value;
        // set_approver_name(value); 
      };

    const handle_signture = () => {
      onApproveProposal();
      };
    
              // const signatureData = signatureRef.current.toDataURL();

              
      const handleApprove = () => {
        onApprove(tipRate);    
      }
      

    return (
        <div>
        {isOpen && (
            <div className='popup_container px-5 text-light mx-auto'>


              { status === "proposal" && (
                <div className='popup_content border-light-1px_O20 px-4 py-4'>
                    <div className='border-bottom-blue-light-1px mx-2 my-4 '>
                        <input
                            className='form-control-details-clean capitalize text-center '
                            type="text"
                            placeholder='your full name'
                            onChange={handle_approver_name_change}
                            />
                    </div>
                  {/* <div className="popup_inner p-2 m-2 border-light-1px">
                      <p className='fs-22 fw-300 light-text opacity-50 mt-2'>Draw Your Signature</p>
                        <SignatureCanvas
                        ref={signatureRef}
                        penColor="white"
                        canvasProps={{ width: 200, height: 100, className: 'signature-canvas' }}
                        />
                    </div> */}
                    <div className='d-inline-flex'>
                      <div className='px-3 fs-18 fw-300 pointer'><p onClick={onClose}>Cancel</p></div>
                        <div className='px-3 fs-18 fw-300 pointer'><p onClick={handle_signture}>Approve</p></div>
                    </div>
                </div>
                )}

                { status === "approve_final" && (
                    <div className='popup_content border-light-1px_O20 px-5 py-3'>
                    <div className='border-bottom-blue-light-1px mx-2 my-4 '>
                      <p>Yay! Let's make it done</p>
                      <p>Add Tip</p>
                      <p>Skip Tip</p>
                    </div>

                    <div className='border-bottom-blue-light-1px mx-2 my-4 '>
                      <p>Do you approve the final versions?</p>
                    </div>


                    <div className='d-inline-flex'>
                      <div className='px-3 fs-24 fw-300 btn-approve pointer opacity-75'><p onClick={onClose}>Cancel</p></div>
                        <div className='px-3 fs-24 fw-300 btn-cancel pointer'><p onClick={handle_signture}>Approve</p></div>
                    </div>
                </div>
                )}



              { status === "final review" && (
                <div>
                  { windowStep === 0 ? (
                <div className='popup_content border-light-1px px-4 py-4'>
                  <p className='capitalize fs-24'>Approve production finish</p>
                    <div className='mx-4 py-4'>
                      <p className='capitalize fs-14'>If you are happy with the final version and you have all the materials in this scope - press the approve button below to set the production as Done</p>
                    </div>
                    {/* <div className='border-bottom-blue-light-1px mx-2 my-4 '>
                        <input
                            className='form-control-details-clean capitalize text-center '
                            type="text"
                            placeholder='your full name'
                            onChange={handle_approver_name_change}
                            />
                    </div> */}
                    <div className='d-inline-flex'>
                      <div className='px-3 fs-18 fw-300 pointer'><p onClick={onClose}>Cancel</p></div>
                        <div className='px-3 fs-18 fw-300 pointer'><p onClick={() => { setWindowStep(1) }}>Approve</p></div>
                    </div>
                </div>
                         ):(
                <div className='popup_content border-light-1px px-4 py-4'>
                  <p className='capitalize fs-24 fw-500'>Add Tip to the bill?</p>
                    <div className=' '>
                      <div className='row my-4 mx-0 text-center justify-content-center'>
                      <div className={`m-2 py-3 col-auto pointer ${tipRate === 5 ? "text-orange border-orange" : "text-light grey-background"}`}>
                      <p onClick={() => { setTipRate(5)}} className='capitalize fs-16'>5%</p>
                        </div>
                        <div className={`m-2 py-3 col-auto pointer ${tipRate === 10 ? "text-orange border-orange" : "text-light grey-background"}`}>
                        <p onClick={() => { setTipRate(10)}} className='capitalize fs-16'>10%</p>
                        </div>
                        <div className={`m-2 py-3 col-auto pointer ${tipRate === 15 ? "text-orange border-orange" : "text-light grey-background"}`}>
                        <p onClick={() => { setTipRate(15)}} className=' capitalize fs-16'>15%</p>
                        </div>
                        <div className={`m-2 py-3 col-auto pointer ${tipRate === 0 ? "text-orange border-orange" : "text-light grey-background"}`}>
                        <p onClick={() => { setTipRate(0)}} className=' capitalize fs-16'>0%</p>
                        </div>
                        </div>
                    </div>
                    <div className='d-inline-flex'>
                      <div className='px-3 mx-3 fs-18 fw-300 pointer'><p onClick={() => { onClose(true); }}>Cancel</p></div>
                        <div className='px-3 mx-3 fs-18 fw-600 pointer'>
                          <p onClick={() => { handleApprove(); onClose(true); }}>Approve</p></div>
                    </div>
                </div>
                
                )}
                </div>
                )}


            </div>
         )} 
    </div>
  );
};


export default ApprovalWindow;
