import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../../menus/Menu';



const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    getClients();

    
  }, []);

  const getClients = async () => {
    try {
      const response = await axios.post('https://a8vgho2ksd.execute-api.us-west-1.amazonaws.com/default/monet-functions',
        {
          command: "clients",

        });

      console.log(response.data);
      setClients(response.data.clients);


    } catch (error) {
      console.error(error);
    }
  };



    return (
      
    <div className="dark-background h-100">
    <div className=''>
        <WorkspaceMenu/>
          </div>
              <div className='col content py-3'>
      

              <p className='fs-14 px-3 mb-3 fw-500 capitalize text-light'>clients</p>
 
          </div>
          </div>


  );
};


export default ClientsList;

