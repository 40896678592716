import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../../menus/Menu';

import icnClose from '../../../assets/icns/close-proposal.svg';
import icnMore from '../../../assets/icns/more-icn.svg';
import deleteIcn from '../../../assets/icns/trash.svg';



const HoverIconSection = () => { 
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const handleMouseMove = (event) => {
  //     setMousePosition({ x: event.clientX, y: event.clientY });
  //   };


    

    
  return (
    <div className="row"> 
     

          <div className="border-bottom-yellow-light-1px border-top-yellow-light-1px"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
            <p className='text-orange text-center py-2 m-0 fw-600 pointer'>
            Add
            </p>
          </div>
    </div>
  );
};




  const ProposalList = () => {
    const [pipelines, setPipelines] = useState([]);
    const [isChecked, setIsChecked] = useState(false);


    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };







  const [newAgentShots, setAgentShots] = useState([]);

  useEffect(() => {
      const initialShot = {
      id: 1,
      action: '',
      dialogue: ''
    };

        if (pipelines){
            setAgentShots(pipelines);
            console.log('moved',newAgentShots)
        }
        else{
            setAgentShots([initialShot]);
        }


}, [pipelines]);


const handleAddNewShot = (index) => {
    const newShot = {
    id: pipelines.length + 1, 
    action: '',
    dialogue: '',
    };

    const updatedShots = [...newAgentShots];
    updatedShots.splice(index, 0, newShot);

    const reindexedTasks = updatedShots.map((shot, idx) => ({
    ...shot,
    id: idx + 1,
    }));

    setAgentShots(reindexedTasks);
};

const updateAddNewShot = (index, field, value) => {
    const updatedShots = [...newAgentShots];
    updatedShots[index][field] = value;
    setAgentShots(updatedShots);
};

const handleDeleteNewShot = (index) => {
    const updatedShots = newAgentShots.filter((_, idx) => idx !== index);

    // Reassign IDs to tasks based on the new order
    const reindexedTasks = updatedShots.map((shot, idx) => ({
      ...shot,
      id: idx + 1,
    }));

    setAgentShots(reindexedTasks);
  };







    return (
      <div className='dark-background h-100 row'>
      <div className='d-flex width-80px'>
        <WorkspaceMenu/>
          </div>
          <div className='col'>
            <div className='row m-0 py-3 text-yellow-light'>
              <p className='fs-18 fw-100 capitalize text-orange'>proposals</p>
                <Link to={`/workspace/new`}>
                    <div className='mb-3' alt="add shot" ><HoverIconSection/></div>
                </Link>
                {/* <div className='mb-3' alt="add shot"  onClick={() => handleAddNewShot(0)}><HoverIconSection/></div> */}
                      {pipelines.sort((a, b) => {
                        const dateA = a.date.split('-').reverse().join('-');
                        const dateB = b.date.split('-').reverse().join('-');
                        return new Date(dateB) - new Date(dateA);
                      }).map(pipeline => (
                  <div className='capitalize border-bottom-yellow-light-1px my-1 fs-14 fw-200' key={pipeline.id}>
                    <div className='pointer'>
                      <Link to={`/workspace/pipeline/${pipeline.id}`}>
                          <div className="d-inline-flex pe-3">
                            <img onClick={() => handleDeleteNewShot(pipeline.id)} src={deleteIcn} width="15px"/>
                          </div>
                          <p className='d-inline-flex text-orange-light pe-3 fw-500'>
                            {pipeline.id}
                          </p>
                          <p className='d-inline-flex text-orange-light pe-3 fw-500'>
                            {pipeline.title}
                          </p>
                          <p className='d-inline-flex text-orange-light pe-3 fw-200'>
                            {pipeline.description}
                          </p>
                          <p className='d-inline-flex text-orange-light fw-200'>
                            {pipeline.client}
                          </p>
                          <div className='d-flex text-orange align-items-top float-end px-2'>
                            {pipeline.date}
                          </div>
                      </Link>
                    </div>
                      


                            {/* <div className="px-2">
                                <textarea
                                    className='col-12 form-control-details-clean text-dark fs-16  p-2'
                                    type="text"
                                    name="dialogue"
                                    style={{ height: '100%' }}
                                    value={shot.dialogue}
                                    onChange={(e) => updateAddNewShot(index, 'dialogue', e.target.value)}
                                    placeholder="Character dialogue"
                                    rows={2}
                                />
                            </div> */}
                            </div>
                    ))}



                </div>
             </div>
          </div>
  );
};


export default ProposalList;

