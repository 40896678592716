import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/icns/logo.svg';
import icnNewOrange from '../assets/icns/newOrange.svg';
import icnNewDark from '../assets/icns/newDark.svg';
import icnHistory from '../assets/icns/historyIcn.svg';
import icnHistoryDark from '../assets/icns/historyIcnDark.svg';
import icnClients from '../assets/icns/clients.svg';
import icnClientsDark from '../assets/icns/clientsDark.svg';
import icnProds from '../assets/icns/production-icn.svg';
import icnProdsDark from '../assets/icns/productionIcnDark.svg';
import icnDone from '../assets/icns/receipt.svg';
import icnDoneDark from '../assets/icns/receiptDark.svg';




const WorkspaceMenu = () => {
  const location = useLocation(); // Get current route (if using React Router)
  
  const menuItems = [
    { name: "home", path: "/", icon: logo },
    { name: "new", path: "/new", activeicon: icnNewDark, icon: icnNewOrange },
    { name: "productions", path: "/productions", activeicon: icnProdsDark, icon: icnProds },
    { name: "done", path: "/done", activeicon: icnDoneDark, icon: icnDone },
    { name: "archived", path: "/workspace/archived", activeicon: icnHistoryDark, icon: icnHistory },
    { name: "clients", path: "/workspace/clients", activeicon: icnClientsDark, icon: icnClients },

  ];
  const isActive = (index, path) => index > 0 && location.pathname === path;


  return (
    <div className='h-100 position-fixed width-75px'>
      <div className='d-flex flex-column align-items-center'>

      {menuItems.map((item, index) => (
        <Link key={item.name} to={item.path} className='my-3'>
          <div className={`menu-item ${isActive(index, item.path) ? 'active' : ''}`}>
          <img style={{ width: '45px'}} className=' pointer' 
                  src={isActive(index, item.path) ? item.activeicon : item.icon}

           alt="logo icon"/>
          </div>
        </Link>
      ))}


    </div>
  </div>
      );
    }
  
  export default WorkspaceMenu;
  