import React, { useRef } from 'react';

// sendDataToParent
const ContractService = ({ production, productionDetails, contractExport }) => {

    // const fileName = (pipeline_data.title);

	const reportTemplateRef = useRef(null);

    if (contractExport === true){
        handleGeneratePdf()
    
    }

	const handleGeneratePdf = () => {
        // sendDataToParent(document.getElementById('contractParagraph').innerHTML)
	};

return (
    <div className='pe-5 py-4 m-0 fs-14' id='Contract' >
    <div>
      <div className='flex-row'>
        {/* <h4 className='d-inline-flex fs-12 fw-100 mb-4'>Contract</h4> */}
        <p className="d-inline-flex float-end text-light pointer" onClick={handleGeneratePdf}></p>
      </div>
  
    </div>
        <div className='' ref={reportTemplateRef} id='contractParagraph'>
        <p>
        This Artwork Commission Agreement ("Agreement") is entered into by and between Fezzik [Anmetz ltd] (“the company") and {production.details.client} ("Client") as of {production.details.date}.
        </p>
        <p>
        1. ARTWORK DESCRIPTION: the company agrees to create the artwork {production.details.name}, {production.details.description}, and incorporated by reference.
        </p>
        <p>
        2. REVISIONS: This Agreement includes {production.terms.revisions} revisions. Additional revisions may be subject to an extra fee of {production.terms.extra} ILS per revision round. The revision round is calculated as an extra working hour and is subject to the capacity of the requested changes.
        </p>
        <p>
        3. PRODUCTS: This proposal includes the following materials, [products], in the table above only, and it is not including anything else that isn't written in the materials.
        </p>
        <p>
        4. EXTRAS AND OPEN FILES: Additional concepts may be provided at an additional cost in a separate proposal. 'Open Files', meaning: the files that used to make the project, are charged separately for the materials created, meaning this proposal does not include the open files of the materials provided in this service. Delivering 'Open Files' will be an extra charge of {production.open_files_price}% of the total price of this proposal.
        </p>
        <p>
        5. CANCELLATION POLICY: In the event of cancellation by the Client, cancellation fees may apply as follows: 20% of the total price of the proposal in case of cancellation after the proposal is approved. 50% of the total price of the proposal in case of cancellation after the delivery of the first sketches or drafts for review. 100% of the total price of the proposal in case of cancel after delivery of all materials for review.
        </p>
        <p>
        6. PAYMENTS DELAYS: Delays in payments will be charged as a 'delay fee' of 200ILS per delayed day. The delayed payment will start counting from the day of the last payment date mentioned in the proforma file.
        </p>
        <p>
        7. RIGHTS RESERVED: All rights for the materials remain with the Client after the approval of the final drafts; the Company retains the rights to use the materials for the Company's portfolio promotion and create related materials for portfolio use. After the final approval of the materials, the Client has the license to use the artwork for commercial and personal purposes.
        After delivering all materials to the Client and receiving the final approval, the Company is not responsible for any damage, changes, or other issues related to the materials.
        </p>
        <p>
        8. DEADLINES: The estimated delivery date for the final artwork is {production.delivery_date}. Timely feedback and cooperation from the Client are essential for meeting deadlines. Delays caused by the Client may result in adjustments to the delivery schedule.
        </p>
        <p>
        9. PAYMENTS: The total agreed-upon price for the artwork is {production.total_amount}, payable as follows: {production.payment_term}.
        </p>
        <p>
        10. GENERAL CONCLUSION: This artwork includes one concept and {production.revisions} revisions. The payment term is {production.payment_term}. Any additional revision will be an extra charge of {production.extra_revisions} per revision round. Revision round calculated as an extra working hour based on the time needed for the change requests.
        </p>
        <p>
        11. GOVERNING LAW: This Agreement shall be governed by and construed in accordance with the laws of the state of Israel. IN WITNESS WHEREOF, the parties hereto have executed this Artwork Commission Agreement as of the date first above written.
        </p>


        </div>       
        </div>

);
};


export default ContractService;