import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../../menus/Menu';
import deleteIcn from '../../../assets/icns/trash.svg';




  const ArchivedList = () => {

    const [productions, setProductions] = useState([]);


    useEffect(() => {
      getProductions();
  
      
    }, []);
  
    const getProductions = async () => {
      try {
        const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
          {
            command: "archived_productions",
            userKey: "paz"
  
          });
  
        console.log(response.data);
        setProductions(response.data.productions);

  
      } catch (error) {
        console.error(error);
      }
    };







    return (
      <div className='dark-background h-100 row'>
      <div className='d-flex width-80px'>
        <WorkspaceMenu/>
          </div>
          <div className='col'>
            <div className='row m-0 p-0 text-yellow-light'>
              <div className='my-2'>
                <p className='fs-14 p-0 m-0 fw-300 capitalize text-light'>Archived</p>
                <p className='fs-14 p-0 m-0 fw-300 capitalize text-orange'>filter</p>

                  </div>
{/* 
                {productions.
                sort((a, b) => {
                  const dateA = a.date.split('-').reverse().join('-');
                  const dateB = b.date.split('-').reverse().join('-');
                  return new Date(dateB) - new Date(dateA);
                  }).
                  map(production => ( */}


                    {productions.map(production => (

                  <div className='capitalize border-top-yellow-light-1px fs-14 fw-200' key={production.id}>
                    <div className='pointer my-2'>
                      <Link to={`/archived-production/${production.accesskey}`}>
                          <div className="d-inline-flex">
                            {/* <img onClick={() => handleDeleteNewShot(production.id)} src={deleteIcn} width="15px"/> */}
                          </div>
                          <p className='d-inline-flex text-orange-light pe-3 m-0 fw-500'>
                            {production.details.name}
                          </p>
                          <p className='d-inline-flex text-light m-0 pe-3 fw-200'>
                            {production.details.client}
                          </p>
                          <p className='d-inline-flex text-light m-0 fw-200'>
                            Closed: {production.details.date}
                            </p>
                          <div className='d-flex text-light align-items-top m-0 float-end px-2'>


                            <p className='d-flex text-orange m-0 fw-200 px-2'>
                              {production.case}
                            </p>
                          </div>

                          
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
        </div>
    </div>  );
};


export default ArchivedList;

