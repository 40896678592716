import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

import fezziklogo from '../../assets/icns/logo-light.svg';
import linkIcn from '../../assets/icns/link.svg';
import arrowDownIcn from '../../assets/icns/arrowDownIcn.svg';

import ApprovalWindow from './components/ApprovalWindow';
import ContractService from './components/contract-service';








const ProductionView = () => {



 
  const { key } = useParams(); 
  const [production, setProduction] = useState(null); 
  const [status, setStatus] = useState(''); 
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [base64String, set_signture] = useState();


  const [is_contract_display, set_contract_display] = useState(false);
  const handle_contract_display_toggle = () => {
    set_contract_display(!is_contract_display);
  };


  
    useEffect(() => {
      getProduction();

    }, []);


        
    useEffect(() => {
      setProgress(0); 
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);


              setIsLoading(false)


            return 100;
          }
          return prev + 10; 
        });
      }, 200); 


      return () => clearInterval(interval); 
  }, [isLoading]);
  

      

  const getProduction = async () => {
    setIsLoading(true)

      
    try {
      const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
        {
          command: "production",
          userKey: "paz",
          accessKey: key,
      });

      const productionData = response.data.production[0];

      setProduction(productionData);
      setStatus(productionData.currentstatus);  

      // if (productionData && productionData.accessKey) {

      // }
      // else{
      //   window.location.href = '/404';

      // }

      

    } catch (error) {
      console.error(error);
    }

    
  };





  const updateProduction = async () => {
    setIsLoading(true)

          try {
            const response = await axios.post('https://9ssruy5b69.execute-api.eu-west-1.amazonaws.com/default/monet-approvals',
              {
                command: "update_production",
                userKey: "paz",
                production: production,
                accessKey: key,

            });

            if (response){
              getProduction();
            }


          } catch (error) {
            console.error(error);
          }

  };


  

  const updateStatus = async () => {
    setIsLoading(true)

          try {
            const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
              {
                  command: "status_change",
                  status: production.currentstatus,
                  accessKey: key,
              });

            if (response){
              getProduction();
            }


          } catch (error) {
            console.error(error);
          }

  };


  const handleApprove = (tipRate) =>{

    production.tipAdded = tipRate;
    production.currentstatus = "bill requested"
    setIsPopupOpen(false);
    updateStatus();

  }

  const handleApproveProposal = () =>{

    production.currentstatus = "proposal approved"
    setIsPopupOpen(false);
    updateProduction();

  }

  const handle_approve_close = () =>{
    setIsPopupOpen(false);

  }



          // let status_color = '';
          // let status_border ='';

          // if (production.currentstatus === 'progress') {
          //   status_color = 'text-blue';
          //   status_border = 'border-blue'
          // } else if (production.currentstatus === 'proposal') {
          //   status_color = 'text-orange';
          //   status_border = 'border-orange'
          // } else if (production.currentstatus === 'retainer') {
          //   status_color = 'text-yellow';
          //   status_border = 'border-yellow'
          // }

    


          const handleDownloadPdf = async () => {
            try {
        
              setIsLoading(true);
        
              const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
                {
                  command: "download_link",
                  accessKey: key,
              });
        
        
        
        
              if (response) {
                const { url } = response.data;
                const fileResponse = await axios.get(url, {
                  responseType: 'blob', 
                });
        
                const blob = new Blob([fileResponse.data], { type: fileResponse.data.type });
                const blobUrl = window.URL.createObjectURL(blob);
        
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'file.pdf');  // Set the desired file name
                document.body.appendChild(link);
                link.click();
        
                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl); 
                setIsLoading(false);
        
              }
            } catch (error) {
              console.error("Error fetching file: ", error);
              alert("File not found or some error occurred");
            }
          };




 


  return (
      <div className='dark-background h-100'>
              {isLoading && (
                <div className="position-fixed m-0 p-0" style={{ width: '100%', backgroundColor: '#000', borderRadius: '0px', opacity: 1, transition: 'backgroundColor 4s ease-in-out' }}>
                        <div className="gradient-green-background py-1"
                            style={{
                            width: `${progress}%`,
                            transition: 'width 0.2s ease-in-out'
                            }}></div>
                        </div>
                      )}






          {production && (
            <div className='container-lg'>

              <ApprovalWindow
                isOpen = {isPopupOpen}
                onClose = {handle_approve_close}
                onApprove = {handleApprove}
                onApproveProposal = {handleApproveProposal}
                updateStatus = {setStatus}
                status = {status}
                />
              
              <header className="py-3">
                    <div className="row align-items-center px-2">
                      <div className="col-auto">
                        <img className='company_logo d-inline-flex' width={"100px"} src={fezziklogo} alt="logo company"/>
                      </div>
                      <div className="col"></div>

                      <div className="col-auto">

                        {production.currentstatus === "final review" ? (
                          <p onClick={() => { setIsPopupOpen(true)}} 
                          className={`capitalize text-yellow border-yellow px-3 py-1 border-6 pointer fw-500`}
                          >Approve Final Delivery</p>
                        
                        ) : production.currentstatus === "proposal" ? (
                      
                          <p onClick={() => { setIsPopupOpen(true)}} className='capitalize text-orange border-orange px-3 py-1 rounded-4 pointer fw-500'>Approve Proposal</p>

                        ) : production.currentstatus === "bill requested" ? (
                        <p className='capitalize text-yellow'>Bill Requested</p>
                        ):(
                          <p className='capitalize text-orange-light pointer'>{status}</p>

                        )}
                        
                        {/* <span className={`${status_color} ${status_border} px-2 capitalize`}>{production.currentstatus}</span> */}

                      </div>
                    </div>
                  </header>




                  <div className='row text-light'>
                  <div className='col-12 px-4 py-4'>



                  <div className='col-12 fs-16 fw-200' >
                    <div className='d-inline-flex fw-500 capitalize'>
                      <p>
                        <span className='pe-2 text-orange-light'>{production.details.name}</span><br></br>
                      </p>
                      <p>
                        <span className='pe-2 text-orange-light'>{production.details.client}</span><br></br>
                      </p>
                    </div>
                        <div className='float-end d-flex'>
                        <span className='pe-2 text-orange-light'>{production.details.date}</span>
                      </div>
                    </div>

                    <div className='col-12 fs-16 fw-200' >
                      <span className='mt-2 fw-300'>{production.details.description}</span>
                    </div>



              </div>
              </div>





    
{/* 
          <div className='row py-2 text-light'>
          <div className='col-12 px-4 py-4'>
            <p className='fw-400 fs-16 text-orange-light capitalize'>Docs</p>

            {production.docs && Array.isArray(production.docs) && (
            <>
                  {production.docs
                    // .sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')))
                    .slice()
                    .reverse()
                    .map((doc, doc_index) =>

                
                    <div className='mt-3' key={doc_index}>
                      <div onClick={handleDownloadPdf} 

                        className={`row m-0 p-0 grey-background w-100 mb-2 border-6 py-2 pointer ${doc.name === 'invoice' ? 'yellow-light-background' : 'grey-background'}`}>
                        <div className="col-10 d-inline-flex text-left capitalize">
                      
                        <p className="fw-400 pe-3">↗</p>
                          <p className="fw-400">{doc.name}</p>
                        </div> 
                        <div className="col d-flex justify-content-end fw-500">
                        <p className="fw-400">{doc.date}</p>

                        </div>
                      </div>
                    </div>

                )}
              </>
            )}
        </div>
        </div> */}



<div className='row py-2 text-light'>
          <div className='col-12 px-4 py-4'>
            <p className='fw-400 fs-16 text-orange-light capitalize'>Docs</p>

            {production.docs && Array.isArray(production.docs) && (
            <>
                  {production.docs
                    // .sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')))
                    .slice()
                    .reverse()
                    .map((doc, doc_index) =>

                
                    <div className='mt-3 grey-background rounded-2 p-2' key={doc_index}>
                      <Link to={doc.link}>
     

                        <div className="col-10 d-inline-flex text-left capitalize">
                      
                        <p className="fw-400 pe-3">↗</p>
                          <p className="fw-400">{doc.name}</p>
                        </div> 
                        <div className="col d-flex justify-content-end fw-500">
                        <p className="fw-400">{doc.date}</p>

                        </div>
         
                      </Link>
                      </div>
                )}
              </>
            )}
        </div>
        </div>


        {production.details.type == "retainer" &&(
        <div className='row py-4 px-3'>
          <div className=' mt-2'>


          <div className='col-12 fs-16 fw-200'>
            <div className='d-inline-flex fw-500 capitalize'>
            <h4 className='fw-500 text-orange-light fs-18 capitalize'>
            Retainer
            </h4>
            </div>
              <div className='float-end'>

            </div>
        </div>

        <div className='col-12 fs-16 fw-200 text-light'>
            <div className=''>
            <p className='fw-500 fs-16 capitalize mb-2 text-orange'>
            Hours Left. {production.details.retainer.hoursLeft}
            </p>

            <p className='fw-200 fs-16 capitalize'>
            Hours purchased. {production.details.retainer.hours}
            </p>

            <p className='fw-200 fs-16'>
            pph. {production.details.retainer.price} <span className='allCaps'>{production.details.currency}</span> 
            </p>
            </div>

        </div>


      </div>
      </div>
        )}

    

        <div className='row py-2 text-light'>
          <div className='col-12 px-4 py-4'>
            <p className='fw-400 fs-16 text-orange-light capitalize'>Feedbacks</p>

            {production.feedbacks && Array.isArray(production.feedbacks) && (
            <>
                  {production.feedbacks
                    // .sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')))
                    .map((feedback, feedback_index) =>

                    <div className='mt-3' key={feedback_index}>
                      <div className="row m-0 p-0 grey-background w-100 mb-2 border-6 py-2">
                        <div className="col-10 d-inline-flex text-left capitalize">
                      
                        <p className="fw-400 pe-3">↗</p>
                          <p className="fw-400">{feedback.chat}</p>
                        </div> 
                        <div className="col d-flex justify-content-end fw-500">

                        </div>
                      </div>
                    </div>
                )}
              </>
            )}
        </div>
        </div>







        <div className='row py-2 text-light'>
        <div className='col-12 px-4 py-4'>
            <p className='fw-400 fs-16 text-orange-light capitalize'>materials</p>
                {production.materials && Array.isArray(production.materials) && (
                <>
                {production.materials.map((material, material_index) => (
                    <div className="row mt-3 mx-0 p-0 grey-background w-100 mb-2 border-6" key={material_index}>
                        {production.details.type != "retainer" ?(
                        <div className='col-12 p-2 fs-16 fw-200'>
                            <div className='d-inline-flex pe-2 fw-500 capitalize'>
                            <p className="fw-500">{material.title}</p>
                            {(new Date(material.dateAdded) > new Date(production.details.date)) && (
                                <p className='fs-12 fw-400 px-3 text-yellow'>Added After Proposal</p>
                              )}
                            </div>
                              <div className='d-flex align-items-top float-end fw-200 fs-12'>
                                <p className="text-end">{material.quantity}</p>
                                <p className="px-4 text-end">{material.price}</p>
                                <p className="text-end">{material.total}</p>
                            </div>
                          </div>
                          ):(
                            <div className='col-12 p-2 fs-16 fw-200'>
                            <div className='d-inline-flex pe-2 fw-500 capitalize'>
                            <p className="fw-500">{material.title}</p>
                            </div>
                              <div className='d-flex align-items-top float-end fw-200 fs-12'>
                              <p className="text-end px-2"><span className='fs-12 fw-200'>hours</span></p>
        
                              <p className="text-end">{material.hours}</p>
                            </div>
                          </div>

                    
                        )}

                      {material.files.map((file, fileIndex) => (
                        <div className='col-12 px-3  py-2 fs-16 fw-200' key={fileIndex}>
                          <div className='d-inline-flex d-flex align-items-top'>

                            <img className='me-2' src={linkIcn} width="20px"/>
                              <Link to={file.link} target='blank'>
                              <p className='fw-300 fs-12 capitalize'>{file.link}</p>
                              </Link>
                            </div>
                              <div className='d-flex align-items-top float-end '>
                                  <p className='fw-400 fs-12 capitalize'>{file.date}</p>
                            </div>
                          </div>
                          ))}

                    </div>


                ))}
                </>
              )}
            </div>
          </div>

          


          <div className='row py-2 text-light'>
          <div className='col-12 px-4 py-4'>

          {production.updatedPrice[0] > production.price[0] ?(
                <div className='col px-4 py-4 grey-background rounded-4'>
                <p className='fw-400 fs-16 text-yellow-light capitalize'>Updated Price</p>

                      <div className='p-2 border-6 p-2 mt-2 fs-16 fw-500'>
                      <div className='pe-2 capitalize'>
                      <p className="mb-2 py-2 border-bottom-light-1px">Price: {production.updatedPrice[0]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>
                      <p className="mb-2 py-2 border-bottom-light-1px">Discount: {production.updatedPrice[1]}  <span className='fs-12 allCaps'>%</span> </p>
                      <p className="mb-2 py-2 border-bottom-light-1px">VAT: {production.updatedPrice[2]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>
                      <p className="mb-2 py-2 text-yellow">Total: {production.updatedPrice[3]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>

                      </div>
                    </div>
                </div>
                ):(null)}



        <div className={`col mt-3 px-4 py-4 ${production.updatedPrice[0] > production.price[0] ? 'opacity-25' : 'opacity-100'}`}>
            <p className='fw-400 fs-16 text-orange-light capitalize'>Original Price</p>
                      <div className='col-6 p-2 border-6 p-2 mt-2 fs-16 fw-500'>
                      <div className='pe-2 capitalize'>
                      <p className="mb-2 py-2 border-bottom-light-1px">Price: {production.price[0]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>
                      <p className="mb-2 py-2 border-bottom-light-1px">Discount: {production.price[1]}  <span className='fw-200'>%</span> </p>
                      <p className="mb-2 py-2 border-bottom-light-1px">VAT: {production.price[2]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>
                      <p className="mb-2 py-2 text-light">Total: {production.price[3]} <span className='fs-12 allCaps'>{production.details.currency}</span> </p>

                      </div>
                    </div>
                </div>
         


                </div>
              </div>





              <div className='row my-4 mx-0 px-3 pointer' onClick={handle_contract_display_toggle}>
            <div className='col-12 grey-background border-6 p-2 fs-16 fw-200 text-light'>
              <h4 className='d-inline-flex text-light align-items-center fs-18 m-0 p-0'>Work Agreement</h4>
              <img src={arrowDownIcn} style={{ width: '35px'}} className='d-inline-flex float-end align-items-center'/>

                  {is_contract_display && (
                    <ContractService  production={production} productionDetails={production} />
                  )}
                </div>
            </div>


            <div className='row mb-4 px-3'>
              <div className='col-12 py-4 d-flex'>
                { production.currentstatus == "proposal" ? (
                  <p onClick={handleApproveProposal} className='pointer me-3 text-orange fw-500 border-orange px-4 py-1 rounded-4 '>Approve Proposal</p>
                ):(null)}

                </div>
            </div>




          </div>

      )}



    </div>
  );
};


export default ProductionView;

