import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../menus/Menu';



const DashboardIndex = () => {
  const [productions, setProductions] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [pendingPaymentsProductions, setPendingPaymentsProductions] = useState([]);


  useEffect(() => {
    getProductions();

    
  }, []);

  const getProductions = async () => {
    try {
      const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
        {
          command: "productions",
          userKey: "paz",


        });


      setProductions(response.data.productions);
      const fillterProposals = response.data.productions.filter(production => production.currentstatus === 'proposal');
      setProposals(fillterProposals);
      const fillterPendingPayments = response.data.productions.filter(production => production.currentstatus === 'payment pending');
      setPendingPaymentsProductions(fillterPendingPayments);

    } catch (error) {
      console.error(error);
    }
  };



    return (

    <div className="dark-background h-100">
      <div className=''>
          <WorkspaceMenu/>
      </div>

      <div className='col content p-4'>
          <div className='col-12 p-4 grey-background border-6 p-2 mt-3 fs-16 fw-200'>
            <h4 className='fs-16 text-light'>Pending Payment</h4>
            {pendingPaymentsProductions.map((pending, pendingIndex) => (
                  <div className='capitalize border-bottom-light-1px py-2 fs-14 fw-200' key={pendingIndex}>
                      <Link to={`/production/${pending.accesskey}`}>
                          <p className='d-inline-flex text-orange-light pe-3 m-0 fw-500'>
                            {pending.details.name}
                          </p>
                          <p className='d-inline-flex text-light m-0 fw-200'>
                            {pending.details.client}
                          </p>
                          <div className='d-flex text-light align-items-top m-0 float-end'>
                            <p className='d-inline-flex text-orange-light fw-200'>
                              {pending.details.date}
                            </p>

                          </div>
                      </Link>
                </div>
            ))};
          </div>


          <div className='col-12 p-4 grey-background border-6 p-2 mt-3 fs-16 fw-200'>
            <h4 className='fs-16 text-light'>Open Proposals</h4>
            {proposals.map((proposal, proposalIndex) => (
              <Link to={`/production/${proposal.accesskey}`} key={proposalIndex}>
                  <div className='capitalize border-bottom-light-1px py-2 fs-14 fw-200'>
                          <p className='d-inline-flex text-orange-light pe-3 m-0 fw-500'>
                            {proposal.details.name}
                          </p>
                          <p className='d-inline-flex text-light m-0 fw-200'>
                            {proposal.details.client}
                          </p>
                          <div className='d-flex text-light align-items-top m-0 float-end'>
                            <p className='d-inline-flex text-orange-light fw-200'>
                              {proposal.details.date}
                            </p>

                          </div>
                </div>
                </Link>

            ))};
              </div>

              

            <div className='col-12 p-4  grey-background border-6 p-2 mt-3 fs-16 fw-200'>
            <h4 className='fs-16 text-light'>Current Month Report</h4>
                <div className='d-flex pe-2 fw-500 capitalize'>
                  <p className="fw-300 fs-14 mt-2 text-orange-light">Income</p>
                </div>

                <div className='d-flex mt-4 pe-2 fw-500 capitalize'>
                  <p className="fw-300 fs-14 text-orange-light">proposal accepted</p>
                </div>

                <div className='d-flex mt-4 pe-2 fw-500 capitalize'>
                  <p className="fw-300 fs-14 text-orange-light">active productions</p>
                </div>

                  {/* <div className='d-flex align-items-center float-end '>
                    <p className="text-end fw-300 fs-12 px-3"></p>
                    <p className='me-2'>↗</p>

                </div> */}
              </div>


     
     
      </div>
    </div>

  );
};


export default DashboardIndex;

