import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

import WorkspaceMenu from '../../menus/Menu';
import ContractService from './components/contract-service';

import icnTrash from '../../assets/icns/trash.svg';
import icnSaveDisk from '../../assets/icns/save-disk-icn.svg';
import moreIcn from '../../assets/icns/more-icn.svg';
import deleteIcn from '../../assets/icns/trash.svg';
import linkIcn from '../../assets/icns/link.svg';
import editIcn from '../../assets/icns/edit.svg';
import notificationIcn from '../../assets/icns/notification.svg';
import arrowDownIcn from '../../assets/icns/arrowDownIcn.svg';




const PipelineIndex = () => {

  const { key } = useParams(); 
  const [production, setProduction] = useState([]); 
  
  const [productionStatus, setProductionStatus] = useState(''); 
  const [productionDetails, setProductionDetails] = useState([]); 
  const [productionMaterials, setProductionMaterials] = useState([]); 
  const [productionDocs, setProductionDocs] = useState([]); 
  const [productionPricing, setProductionPricing] = useState([0, 0, 0, 0]);
  const [productionUpdatedPricing, setProductionUpdatedPricing] = useState([]);
  const [vatRate, setVatRate] = useState(0.17); 


  
  const [reload, setReload] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [progress, setProgress] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownMaterial, setDropdownMaterial] = useState(false);


  

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  }


  useEffect(() => {

    const handleGetProduction = async () => {

    try {
      const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
        {
          command: "production",
          userKey: "paz",
          accessKey: key,
      });

      const productionData = response.data.production[0]
      setProduction(productionData);
      setProductionDetails(productionData.details);
      setProductionMaterials(productionData.materials);
      setProductionDocs(productionData.docs);
      setProductionPricing(productionData.price);
      setProductionStatus(productionData.currentstatus);


      if (productionData.updatedPrice){
        setProductionUpdatedPricing(productionData.updatedPrice);
      }


    console.log(productionStatus);


    } catch (error) {
      console.error(error);
    }

    };

    handleGetProduction();

  }, [reload]);



  useEffect(() => {
    setProgress(0); 
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false)

          return 100;
        }
        return prev + 10; 
      });
    }, 200); 

    return () => clearInterval(interval); 
}, [loading]);











  
  const updateproduction = async (status) => {

    await runCalculations();

    if (production){
    setLoading(true);


    if (status == "" ){
      production.currentstatus = productionStatus;
    }
    else{
      production.currentstatus = status;
    }

    production.materials = productionMaterials;
    production.docs = productionDocs;
    production.updatedPrice = productionUpdatedPricing;


    if (status === "archive"){


      try {
        const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
          {
            command: "archive_production",
            userKey: "paz",
            production: production,
            accessKey: key,
          });
  
          if (response){
            window.location.href = '/';
          }

        } catch (error) {
          console.error(error);
        }

    }

    else{
        try {
          setLoading(true);
            const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
              {
                command: "update_production",
                userKey: "paz",
                production: production,
                accessKey: key,

            });

            if (response){
              setReload(true);
            }


            setLoading(false);

          } catch (error) {
            console.error(error);
          }
        }

      }
      else{
        console.log('empty');
      }
      };

 





          
  const [newDoc, setNewDoc] = useState({name: '', link: ''}); 
  const [newDocWindow, setNewDocWindow] = useState(false); 

  const handleNewDocChange = (e) => {
    const { name, value } = e.target;
    setNewDoc((prevDoc) => ({
      ...prevDoc,
      [name]: value 
    }));
  };


  const handleAddDoc = () => {
    if (newDoc.link.trim() === '') return; 
    setProductionDocs((prevDocs) => [...prevDocs, { ...newDoc }]);
    setNewDoc({ name: '', link: '' });
    setNewDocWindow(false);
  };




    const [newMaterial, setNewMaterial] = useState({ title: '', quantity: '', price: '', total: '', files:[], dateAdded: '', hours: 0 }); 
    const [newMaterialVersionLink, setnewMaterialVersionLink] = useState(''); 
    const [newMaterialVersionDate, setnewMaterialVersionDate] = useState(''); 
    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);
    const [newMaterialWindow, setNewMaterialWindow] = useState(false); 

    
    const handleNewMaterialChange = (e) => {
      const { name, value } = e.target;

        setNewMaterial((prevMaterial) => ({
          ...prevMaterial,
          [name]: value 
        }));



    };


    const handleAddMaterial = () => {
      // if (newMaterial.title.trim() === '' || newMaterial.price.trim() === '') return; 

      const calculatedTotal = parseFloat(newMaterial.price) * parseFloat(newMaterial.quantity);

      setProductionMaterials((prevMaterials) => [...prevMaterials, { ...newMaterial, price: parseFloat(newMaterial.price), total: calculatedTotal, files: [], dateAdded: getCurrentDate() }]);

      const newTotalPrice = production.total_price + calculatedTotal;
      setProductionPricing(newTotalPrice);

      setNewMaterial({ title: '',  quantity: '', price: '', total: '', files: [], dateAdded:'', hours: 0 });
      setNewMaterialWindow(false);
      runCalculations();
    };



    const handleDeleteMaterial = (index) => {

      setProductionMaterials(productionMaterials.filter((_, i) => i !== index));

      runCalculations();
    };





    const handleDeleteMaterialVersion = (materialIndex, fileIndex) => {
      setProductionMaterials((prevMaterials) =>
        prevMaterials.map((material, index) => {
          if (index === materialIndex) {
            return {
              ...material,
              files: material.files.filter((file, idx) => idx !== fileIndex)
            };
          }
          return material; 
        })
      );
    };



    



  const handleAddMaterialVersion = (materialIndex) => {

    if (newMaterialVersionLink.trim() === '' || newMaterialVersionDate.trim() === '') return;

    const newMaterialVersion = { link: newMaterialVersionLink, date: newMaterialVersionDate };


    setProductionMaterials((prevMaterials) =>
      prevMaterials.map((material, index) => {
        if (index === materialIndex) {
          return {
            ...material,
            files: [...material.files, newMaterialVersion] 
          };
        }
        return material;
      })
    );

    setnewMaterialVersionLink(''); 
    setnewMaterialVersionDate(''); 

    setSelectedMaterialIndex(null); 
  };




  const runCalculations = async () => {

    setProductionUpdatedPricing(prevPricing => {
      const updatedPricing = [...prevPricing];

      let sumPrice = 0;
      productionMaterials.forEach((material) => {
        const total = parseFloat(material.total) || 0;
        sumPrice += total;
      });

      updatedPricing[0] = sumPrice;
      console.log(updatedPricing[0]);
      return updatedPricing;
  });


    const price = productionUpdatedPricing[0];
    const discount = productionPricing[1];

    const discountedPrice = Math.round(price - (price * discount / 100));
    console.log(discountedPrice);

    const vatAmount = Math.round(discountedPrice * vatRate);
    console.log(vatAmount);

    const totalPrice = Math.round(discountedPrice + vatAmount);
    console.log(totalPrice);


    setProductionUpdatedPricing((prevPricing) => [
      prevPricing[0],  
      prevPricing[1], 
      vatAmount,     
      totalPrice    
    ]);
  };

  

  

  //status coloring//

  // let status_color = '';
  // let status_border ='';

  // if (pipeline.currentstatus === 'progress') {
  //   status_color = 'text-blue';
  //   status_border = 'border-blue'
  // } else if (pipeline.currentstatus === 'proposal') {
  //   status_color = 'text-orange';
  //   status_border = 'border-orange'
  // } else if (pipeline.currentstatus === 'retainer') {
  //   status_color = 'text-yellow';
  //   status_border = 'border-yellow'
  // }



  const [is_contract_display, set_contract_display] = useState(false);
  const isAdminSide = false;
  const handle_contract_display_toggle = () => {
    set_contract_display(!is_contract_display);
  };





  const handleDownloadPdf = async () => {
    try {

      setLoading(true);

      const response = await axios.post('https://a8vgho2ksd.execute-api.us-west-1.amazonaws.com/default/monet-functions',
        {
          command: "download_link",
          accessKey: key,
      });




      if (response) {
        const { url } = response.data;
        const fileResponse = await axios.get(url, {
          responseType: 'blob', 
        });

        const blob = new Blob([fileResponse.data], { type: fileResponse.data.type });
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'file.pdf');  // Set the desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); 
        setLoading(false);

      }
    } catch (error) {
      console.error("Error fetching file: ", error);
      alert("File not found or some error occurred");
    }
  };




  const [copied, setCopied] = useState(false);
  const baseLink = "http://workspace.fezzik.art/";

  const copyToClipboard = async () => {
    const fullLink = baseLink + key; 
    try {
      await navigator.clipboard.writeText(fullLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };



  return (
    <div className="dark-background h-100">
      <div className=''>
          <WorkspaceMenu/>
            </div>


  <div className='col p-0 content py-3 text-light'>
        

        {loading && (
          <div className="position-fixed m-0 p-0" style={{ width: '100%', backgroundColor: '#000', borderRadius: '0px', opacity: 1, transition: 'backgroundColor 4s ease-in-out' }}>
                  <div className="gradient-green-background py-1"
                      style={{
                      width: `${progress}%`,
                      transition: 'width 0.2s ease-in-out'
                      }}></div>
                  </div>
                )}

      <div className='row m-0 px-3'>
          <div className='col-12 mb-2'>

            <div className='d-inline-flex fw-500 capitalize'>
              <div className='col-12'>
            <p className='fw-400 fs-16 capitalize'>
              {productionDetails.name} . <span className='text-orange'>{productionDetails.client}</span> 
            </p>
            <p className='fw-400 fs-16 capitalize'>
             Created on: {productionDetails.date} . <span className='text-orange'>{productionStatus} </span>

            </p>
            <p className='fw-400 mt-3 fs-16 capitalize'>
            {productionDetails.description}
            </p>
            </div>

            </div>
              <div className='float-end'>
              <img onClick={copyToClipboard} style={{ width: '30px'}} className='pointer me-3' src={notificationIcn} alt="link icon"/>  
              <img onClick={copyToClipboard} style={{ width: '30px'}} className='pointer me-3' src={linkIcn} alt="link icon"/>  
                <img onClick={() => { updateproduction('') }} style={{ width: '30px'}} className='pointer me-3' src={icnSaveDisk} alt="more icon"/>
                <img onClick={() => setDropdown(!dropdown)} style={{ width: '10px'}} className='pointer' src={moreIcn} alt="more icon"/>
                { copied && (<p className='d-flex'>link copied</p>)}              

                <div>
                    {dropdown && (
                            <div className="pipeline-menu mt-1 me-4">
                            <ul className="pipeline-menu-content px-2">
                              <li className="py-2 fw-400">Set Status</li>
                              <div className='mb-3'>
                              <li onClick={() => { setDropdown(!dropdown); updateproduction('proposal') }}  className="fw-200"><p>Proposal</p></li>
                              <li onClick={() => { setDropdown(!dropdown); updateproduction('production')}} className="fw-200"><p>Production</p></li>
                              <li onClick={() => { setDropdown(!dropdown); updateproduction('final review')}} className="fw-200"><p>Final Review</p></li>
                              <li onClick={() => { setDropdown(!dropdown); updateproduction('payment pending')}} className="fw-200"><p>Payment Pending</p></li>
                              <li onClick={() => { setDropdown(!dropdown); }} className="fw-200"><p>Paid</p></li>
                              </div>



                              <p className="border-bottom-light-1px"></p>
                              <li className="py-2 fw-400">New Document</li>
                              <p className="border-bottom-light-1px"></p>
                              <li onClick={() => { setDropdown(!dropdown); updateproduction('closed') }}  className="py-2 fw-400">Terminate</li>
                            </ul>
                          </div>
                            )}
                          </div>

            </div>

            </div>
            </div>




          <div className='row mx-4 px-4 mt-4'>
            <div className='col-12 rounded-3 height-200px border-light-1px p-2'>
              <h4 className='fw-600 text-green-light fs-18 capitalize'>
                Progress
              </h4>



            </div>
          </div>






        <div className='row mt-2 py-4 m-0 px-3'>  
          <div className='col-12 fs-16 fw-200'>
          <div className='row'>
          <div className='col-12 fs-16 fw-200'>
          <div className='d-inline-flex fw-500 capitalize'>
            <h4 className='fw-500 text-orange-light fs-18 capitalize'>
            Docs 
            </h4>
            </div>
            <div className='float-end'>
                  {newDocWindow &&(
                  <p onClick={() => { setNewDocWindow(false)}}
                  className='d-inline-flex px-3 fw-400 capitalize text-light pointer fs-14'>
                    Cancel
                  </p>
                  )}
                  <p onClick={() => { setNewDocWindow(!newDocWindow)}}
                  className='d-inline-flex fw-400 capitalize text-orange pointer fs-14'>
                    Add Doc
                  </p>
              </div>
            </div>

              </div>
                { newDocWindow ? (
                  <div className='col-12 grey-background border-6 p-2 my-4 fs-16 fw-200'>
                    <div className='fw-500 capitalize '>
                      <div className='border-bottom-light-1px'>
                        <input
                        className='col-12 my-1 form-control border-bottom-light-1px'
                        type="text"
                        name="name"
                        value={newDoc.name}
                        onChange={handleNewDocChange}
                        placeholder="name"
                        />
                      </div>
                      <div className='border-bottom-light-1px'>
                        <input
                        className='col-12 my-1 form-control border-bottom-light-1px'
                        type="text"
                        name="link"
                        value={newDoc.link}
                        onChange={handleNewDocChange}
                        placeholder="link"
                        />
                      </div>
                      </div>

                      <div className='p-2'>
                        <p onClick={() => handleAddDoc()}
                        className='fw-500 capitalize text-light pointer fs-14'>
                          Add
                        </p>
                    </div>
                </div>
              ):(null)}
        


          {productionDocs && Array.isArray(productionDocs) && (
            <div className=''>
                  {productionDocs
                    // .sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')))
                    .map((doc, doc_index) =>


                      <div onClick={handleDownloadPdf} className='col-12 p-2 grey-background border-6 p-2 mt-2 fs-16 fw-200 pointer' key={doc_index}>

                      <div className='d-inline-flex pe-2 fw-500 capitalize'>
                      <p className="fw-400">{doc.name}</p>

                      </div>
                        <div className='d-flex align-items-center float-end '>
                          <p className="text-end fw-300 fs-12 px-3">{doc.date}</p>
                          <p className='me-2'>↗</p>

                      </div>
                     
                    </div>

                )}
              </div>
            )}

      </div>



            <div className='col-12 mt-4 fs-16 fw-200'>
              <h4 className='fw-500 text-orange-light fs-18 capitalize'>
                Milestones
                </h4>
              </div>
            </div>









        {productionDetails.type == "retainer" &&(
        <div className='row py-4 m-0 px-3'>
          <div className=' mt-2'>


          <div className='col-12 fs-16 fw-200'>
            <div className='d-inline-flex fw-500 capitalize'>
            <h4 className='fw-500 text-orange-light fs-18 capitalize'>
            Retainer
            </h4>
            </div>
              <div className='float-end'>

            </div>
        </div>

        <div className='col-12 fs-16 fw-200'>
            <div className=''>
            <p className='fw-500 fs-16 capitalize mb-2'>
            Hours Left. {productionDetails.retainer.hours}
            </p>

            <p className='fw-200 fs-16 capitalize'>
            Hours purchased. {productionDetails.retainer.hours}
            </p>

            <p className='fw-200 fs-16'>
            pph. {productionDetails.retainer.price} <span className='allCaps'>{productionDetails.currency}</span> 
            </p>
            </div>

        </div>


      </div>
      </div>
        )}






    <div className='row py-4 m-0 px-3'>
      <div className='col-12 mt-2'>
        
      {productionDetails.type == "project" ? (


      // ADD PROJECT MATERIALS
        <div className='col-12 fs-16 fw-200'>
          <div className='d-inline-flex fw-500 capitalize'>
            <h4 className='fw-500 text-orange-light fs-18 capitalize'>
            materials 
            </h4>
            </div>
              <div className='float-end'>
              {newMaterialWindow &&(
                <p onClick={() => { setNewMaterialWindow(false)}}
                className='d-inline-flex px-3 fw-400 capitalize text-light pointer fs-14'>
                  Cancel
                </p>
              )}
                <p onClick={() => { setNewMaterialWindow(!newMaterialWindow)}}
                className='d-inline-flex fw-400 capitalize text-orange pointer fs-14'>
                  Add Material
                </p>

            </div>

            { newMaterialWindow ? (
            <div className='col-12 grey-background  border-6 p-2 my-4 fs-16 fw-200'>
            <div className='fw-500 capitalize '>

              <div className='border-bottom-light-1px'>
                <input
                className='col-12 my-1 form-control border-bottom-light-1px'
                type="text"
                name="title"
                value={newMaterial.title}
                onChange={handleNewMaterialChange}
                placeholder="title"
                />
              </div>

              <div className='border-bottom-light-1px'>
                <input
                className='col-12 my-1 form-control '
                type="number"
                name="quantity"
                value={newMaterial.quantity}
                onChange={handleNewMaterialChange}
                placeholder="quantity"
                />
              </div>

              <div className='border-bottom-light-1px'>
                <input
                  className='col-12 my-1 form-control border-bottom-light-1px'
                  type="number"
                  name="price"
                  value={newMaterial.price}
                  onChange={handleNewMaterialChange}
                  placeholder="price"
                />
              </div>
              </div>

              <div className='p-2'>
                <p onClick={() => handleAddMaterial()}
                className='fw-500 capitalize text-light pointer fs-14'>
                  Add
                </p>
            </div>
        </div>
        ):(null)}
      </div>
      ):(

// ADD RETAINER MATERIALS HOURS

        <div className='col-12 fs-16 fw-200'>
        <div className='d-inline-flex fw-500 capitalize'>
        <h4 className='fw-500 text-orange-light fs-18 capitalize'>
        materials 
        </h4>
        </div>
          <div className='float-end'>
          {newMaterialWindow &&(
            <p onClick={() => { setNewMaterialWindow(false)}}
            className='d-inline-flex px-3 fw-400 capitalize text-light pointer fs-14'>
              Cancel
            </p>
          )}
            <p onClick={() => { setNewMaterialWindow(!newMaterialWindow)}}
            className='d-inline-flex fw-400 capitalize text-orange pointer fs-14'>
              Add Material
            </p>

        </div>

        { newMaterialWindow ? (
        <div className='col-12 grey-background  border-6 p-2 my-4 fs-16 fw-200'>
        <div className='fw-500 capitalize '>

          <div className='border-bottom-light-1px'>
            <input
            className='col-12 my-1 form-control border-bottom-light-1px'
            type="text"
            name="title"
            value={newMaterial.title}
            onChange={handleNewMaterialChange}
            placeholder="title"
            />
          </div>

          <div className='border-bottom-light-1px'>
            <input
            className='col-12 my-1 form-control '
            type="number"
            name="hours"
            value={newMaterial.hours}
            onChange={handleNewMaterialChange}
            placeholder="quantity"
            />
          </div>

          </div>

          <div className='p-2'>
            <p onClick={() => handleAddMaterial()}
            className='fw-500 capitalize text-light pointer fs-14'>
              Save
            </p>
        </div>
    </div>
    ):(null)}
  </div>

      )}


      {productionMaterials && Array.isArray(productionMaterials) && ( <>
      {productionMaterials.map((material, materialIndex) => (


      <div
      className={`row px-2 m-0 mt-3 grey-background border-6
        `}
        // ${new Date(material.dateAdded) > new Date(production.date) ? 'border-bottom-orange-1px' : ''}

      key={materialIndex}>
        
        <div className='col-12 p-1 fs-16 fw-200'>
            <div className='d-inline-flex'>
                <img onClick={() => { setDropdownMaterial(!dropdownMaterial) }} className='d-inline-flex me-3 fw-500 capitalize' src={moreIcn} width="10px"/>
                {/* <img onClick={() => handleDeleteMaterial(materialIndex)}  className='d-inline-flex me-3 fw-500 capitalize' src={deleteIcn} width="15px"/>
                <img onClick={() => handleDeleteMaterial(materialIndex)}  className='d-inline-flex me-3 fw-500 capitalize' src={editIcn} width="15px"/> */}
                <p className="">{material.title}</p>
            </div>

            {dropdownMaterial && (
              <div className="">
              <ul className=" px-2">
                <li className="py-2 fw-400">Set Status</li>
                <p className="border-bottom-light-1px"></p>
                <li className="py-2 fw-400">New Document</li>
                <p className="border-bottom-light-1px"></p>
                <li onClick={() => { setDropdown(!dropdown); updateproduction('closed') }}  className="py-2 fw-400">Terminate</li>
              </ul>
            </div>
              )}


            {productionDetails.type != "retainer" ?(
              <div className='d-flex align-items-center float-end '>
              {(new Date(material.dateAdded) > new Date(production.details.date)) && (
                  <p className='fs-12 fw-400 px-3 text-yellow-light'>Added After Proposal</p>
                )}

                  <>
                  <p className="pe-4 text-end"><span className='fs-12 fw-200'>q.</span> {material.quantity}</p>
                  <p className="pe-4 text-end"><span className='fs-12 fw-200'>p.</span> {material.price}</p>
                  <p className=" text-end"><span className='fs-12 fw-200'>t.</span> {material.total}</p>
                  
                  </>
            </div>
                ):(
                  <div className='d-flex align-items-center float-end '>
                  <p className="text-end px-2"><span className='fs-12 fw-200'>hours</span></p>

                  {/* {selectedMaterialIndex === materialIndex && (

                  <div className=''>
                    <input
                      className='text-light px-4'
                      type="number"
                      value={newMaterialVersionDate}
                      onChange={(e) => setnewMaterialHours(e.target.value)}
                      placeholder="Hours"
                    />
                    </div>
                    )} */}

                    <p className="text-end">{material.hours}</p>

                    </div>
                  )}



          </div>


          {material.files.map((file, fileIndex) => (
            <div className='col-12 py-2 fs-16 fw-200' key={fileIndex}>
              <div className='d-inline-flex d-flex align-items-top'>
              {/* <p className='fw-500 me-2 fs-12 capitalize'>{fileIndex + 1}</p> */}
              <p className='fw-400 me-2 fs-12 capitalize'>{file.date}</p>

                  <img className='me-2' src={linkIcn} width="20px"/>
                    <Link to={`file.link`} target='blank'>
                    <p className='fw-500 fs-12 capitalize'>{file.link}</p>
                    </Link>
                  </div>
                    <div className='d-flex align-items-center float-end '>
                        <img onClick={() => handleDeleteMaterialVersion(materialIndex, fileIndex)} className='ms-2' src={icnTrash} width="20px"/>

                  </div>
                </div>
              ))}



          {selectedMaterialIndex === materialIndex ? (
          <div className='col-12 py-2 fs-16 fw-200 border-bottom-light-1px'>
            <p className='d-inline-flex pe-2 fw-500 capitalize'> Link</p>
                
                <input
                className='me-2'
                  type="text"
                  value={newMaterialVersionLink}
                  onChange={(e) => setnewMaterialVersionLink(e.target.value)}
                  placeholder="Link"
                />

                <input
                  className='text-light px-4'
                  type="date"
                  value={newMaterialVersionDate}
                  onChange={(e) => setnewMaterialVersionDate(e.target.value)}
                  placeholder="Date"
                />

                <div className='float-end ' >
                  <p onClick={() => handleAddMaterialVersion(materialIndex)} className='text-orange-light fw-500 capitalize pointer' > Add</p>
                </div>
              </div>

            ) : (

                <>
                {production.currentstatus == 'production' && (
                      <div className='col-12 px-2 py-2 fs-16 fw-200'>
                      <p onClick={() => setSelectedMaterialIndex(materialIndex)} className='d-inline-flex pe-2 fw-400 capitalize text-orange pointer fs-14'> Add Version</p>
                      </div>

                  )}
                </>
            )}


          </div>
        ))}
        </>
      )}
    </div>
  </div>

       



      <div className='row py-4 m-0 px-3'>
        <div className='col py-4'>
          <h4 className='fw-500 text-orange-light fs-18 capitalize'>
            price breakdown
              </h4>

              <div className='py-2'></div>

              <div className='col-12 fs-16 fw-200 py-1 '>
                <p className='fs-16 fw-600'>Price. <span className='fw-400'>{productionPricing[0]}</span> <span className='fw-200 allCaps'>{productionDetails.currency}</span>  </p>
                <p className='mt-2 fs-16 fw-600'>Discount. <span className='fw-400'>{productionPricing[1]}</span> <span className='fw-200 allCaps'>%</span>  </p>
                <p className='mt-2 fs-16 fw-600'>VAT. 
                  <span className='fw-400'>{productionPricing[2]}</span> <span className='fw-200 allCaps'>{productionDetails.currency}</span>  </p>
                <p className='mt-2 fs-16 fw-600'>Total. <span className='fw-400'>{productionPricing[3]}</span> <span className='fw-200 allCaps'>{productionDetails.currency}</span>  </p>
                {production.tipAdded ? (
                    <p className='mt-4 text-yellow d-inline-flex pe-3 fs-14 fw-300 capitalize'>
                      Tip Added. {production.tipAdded}
                    </p>
                  ):(
                    <p className='mt-4 d-inline-flex pe-3 fs-14 fw-200 capitalize'>
                      Tip Added. None
                  </p>
                  )}
              
              </div>

          </div>


 

          <div className='col py-4'>
            <p className='fw-500 text-yellow-light capitalize'>
              Updated Price
                </p>

              <div className='py-2'></div>
                <div className='col-12 fs-16 fw-200'>
                  <div className='col-12 py-1'>
                    <p className='fs-14 fw-600'>Price. <span className='fw-400'>{productionUpdatedPricing[0]}</span> <span className='fw-200'>ILS</span>  </p>
                    <p className='fs-16 fw-600'>Discount. <span className='fw-400'>{productionUpdatedPricing[1]}</span> <span className='fw-200 allCaps'>%</span>  </p>
                    <p className='fs-14 fw-600'>VAT. <span className='fw-400'>{productionUpdatedPricing[2]}</span> <span className='fw-200'>ILS</span>  </p>
                    <p className='fs-14 fw-600'>Total. <span className='fw-400'>{productionUpdatedPricing[3]}</span> <span className='fw-200'>ILS</span>  </p>
                  </div>
              </div>
          </div>

    </div>




                
        <div className='row mt-2 mx-0 px-4 pointer' onClick={handle_contract_display_toggle}>
            <div className='col-12 grey-background border-6 p-2 my-4 fs-16 fw-200'>
              <h4 className='d-inline-flex text-light align-items-center fs-18 px-2 m-0'>Work Agreement</h4>
              <img src={arrowDownIcn} style={{ width: '35px'}} className='d-inline-flex float-end align-items-center'/>

                  {is_contract_display && (
                    <ContractService production={production} productionDetails={production} />
                  )}
                </div>
            </div>


            <div className='row mb-4 px-2'>
            <div className='col-12 py-4 d-flex'>
              <p onClick={updateproduction} className='pointer me-3 text-orange fw-500 border-orange px-4 py-1 rounded-4 '>Update</p>


                </div>
            </div>








        </div>
    </div>
  );
};


export default PipelineIndex;

