import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../../menus/Menu';

const ProgressList = () => {
  const [pipelines, setPipelines] = useState([]);

  useEffect(() => {
    axios.get('https://49v955fsjk.execute-api.eu-south-1.amazonaws.com/default/monet-get-pipelines')
    .then(response => {
        const progressPipelines = response.data.filter(pipeline => pipeline.currentstatus === "progress");
            setPipelines(progressPipelines);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }, []);




    return (
      <div className='dark-background h-100 row'>
      <div className='d-flex width-80px'>
        <WorkspaceMenu/>
          </div>
          <div className='col'>
            <div className='row m-0 px-5 py-5 text-yellow-light'>
                <p className='fs-18 fw-100 capitalize text-blue'>progress</p>
                  {pipelines.sort((a, b) => {
                    const dateA = a.date.split('-').reverse().join('-');
                    const dateB = b.date.split('-').reverse().join('-');
                    return new Date(dateB) - new Date(dateA);
                  }).map(pipeline => (
                  <div className=' capitalize border-bottom-yellow-light-1px my-2 fs-18 fw-200'
                    key={pipeline.id}>
                      <Link to={`/workspace/pipeline/${pipeline.id}`}>
                        <p className='d-inline-flex text-yellow-light pe-3 fw-500'>
                        {pipeline.title}
                        </p>
                        <p className='d-inline-flex text-yellow-light pe-3 fw-200'>
                        {pipeline.description}
                        </p>
                        <p className='d-inline-flex text-yellow-light fw-200'>
                        {pipeline.client}
                        </p>
                        <div className='d-flex text-yellow align-items-top float-end px-2'>
                             {pipeline.date}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
             </div>
          </div>
  );
};


export default ProgressList;

